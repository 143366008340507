import React, { useEffect, useState } from "react";
import { db } from "../firebase.config";
import {
  doc,
  serverTimestamp,
  addDoc,
  collection,
  getDoc,
  updateDoc,
  increment,
} from "firebase/firestore";
import { useAuth } from "../hooks/useAuth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import "../css/ReviewForm.css";
import { BeatLoader } from "react-spinners";

const ReviewForm = ({ revieweeUid, revieweeDetails, rentalRequestId }) => {
  const [rating, setRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(0);
  const [comment, setComment] = useState("");
  const [submittingReview, setSubmittingReview] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const changeHasReviewedPropertyToTrue = async (rentalData) => {
    const rentalRef = doc(db, "completed-rentals", rentalRequestId);
    if (currentUser.uid === rentalData.ownerUid) {
      await updateDoc(rentalRef, {
        ownerHasReviewed: true,
      });
    } else if (currentUser.uid === rentalData.renterUid) {
      await updateDoc(rentalRef, {
        renterHasReviewed: true,
      });
    } else {
      // The current user is not part of this rental
      console.log("currentUser is not part of this rental");
      return;
    }
  };

  const incrementReviewCount = async () => {
    const userRef = doc(db, "users", revieweeUid);
    await updateDoc(userRef, {
      reviewCount: increment(1),
      totalRating: increment(rating),
    });
  };

  const createReview = async () => {
    const reviewsCollectionRef = collection(
      db,
      "users",
      revieweeUid,
      "reviews"
    );
    await addDoc(reviewsCollectionRef, {
      comment,
      rating,
      createdAt: serverTimestamp(),
      reviewerUid: currentUser.uid,
      rentalRequestId,
    });
  };

  const updateAverageReviewRating = async () => {
    const userRef = doc(db, "users", revieweeUid);
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      const totalRating = userData.totalRating || 0;
      const reviewCount = userData.reviewCount || 0;

      // Calculate new average rating
      const newAverageReviewRating = totalRating / reviewCount;

      // Update only the averageReviewRating
      await updateDoc(userRef, {
        averageReviewRating: parseFloat(newAverageReviewRating.toFixed(2)),
      });
    }
  };

  const handleSubmitReview = async (e) => {
    e.preventDefault();
    if (!rating || !comment.trim()) {
      toast.error("Please fill in all fields", { autoClose: 3000 });
      return;
    }

    try {
      setSubmittingReview(true);

      const rentalRef = doc(db, "completed-rentals", rentalRequestId);
      const rentalDoc = await getDoc(rentalRef);

      if (rentalDoc.exists()) {
        const rentalData = rentalDoc.data();

        // Check if current user is involved in the rental
        if (
          currentUser.uid !== rentalData.ownerUid &&
          currentUser.uid !== rentalData.renterUid
        ) {
          toast.error("You are not authorised to review this rental", {
            autoClose: 3000,
          });
          setSubmittingReview(false);
          return;
        }

        // Check if the user has already reviewed
        if (
          (currentUser.uid === rentalData.ownerUid &&
            rentalData.ownerHasReviewed) ||
          (currentUser.uid === rentalData.renterUid &&
            rentalData.renterHasReviewed)
        ) {
          toast.error("You have already reviewed this rental.", {
            autoClose: 3000,
          });
          setSubmittingReview(false);
          return;
        }

        // Create review
        await createReview();

        // Increment review count and total rating
        await incrementReviewCount();

        // Recalculate user's average review rating
        await updateAverageReviewRating();

        // Update hasReviewed property
        await changeHasReviewedPropertyToTrue(rentalData);

        toast.success("Review submitted successfully", { autoClose: 3000 });
        // navigate("/");
      } else {
        toast.error("Rental request not found", { autoClose: 3000 });
      }
    } catch (error) {
      toast.error("Failed to submit review");
      console.error("Error submitting review: ", error);
    } finally {
      setSubmittingReview(false);
    }
  };

  return (
    <div>
      <form
        onSubmit={handleSubmitReview}
        className="review-form bg-gray-50 p-6 max-w-md mx-auto"
      >
        <h2 className="font-semibold text-lg mb-4">
          Leave a review for{" "}
          <span className="text-teal-500">
            {revieweeDetails && revieweeDetails.username}
          </span>
        </h2>
        <div className="rating">
          {[1, 2, 3, 4, 5].map((star) => (
            <FontAwesomeIcon
              key={star}
              icon={faStar}
              className={`star ${
                star <= (hoverRating || rating) ? "hover" : ""
              }`}
              onClick={() => setRating(star)}
              onMouseEnter={() => setHoverRating(star)}
              onMouseLeave={() => setHoverRating(0)}
            />
          ))}
        </div>
        <label htmlFor="comment" className="text-sm font-semibold">
          Comment (required)
        </label>
        <textarea
          id="comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          className="mt-2 mb-4 w-full p-2 border border-gray-300 rounded-md"
          rows="4"
          maxLength="500"
          required
        />
        <div className="flex justify-center">
          <button
            type="submit"
            className="btn-continue px-4 py-2 mt-2 w-full sm:w-auto"
            disabled={submittingReview}
          >
            {submittingReview ? <BeatLoader color="white" /> : "Submit Review"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ReviewForm;
