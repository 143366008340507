import React, { useState } from "react";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import StripeVerifyIdentityButton from "./StripeVerifyIdentityButton";

const customStyles = {
  content: {
    inset: "50% auto auto 50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    padding: "24px",
    maxWidth: "600px",
    width: "90%",
    border: "none",
    boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
};

const VerificationModal = ({
  isOpen,
  idVerified,
  stripeAccountId,
  currentUser,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const steps = [
    {
      id: 1,
      title: "Confirm your email address",
      description:
        "Secure your account and receive important updates by verifying your email. Check your inbox and spam folder!",
      completed: currentUser.emailVerified,
    },
    {
      id: 2,
      title: "Sign up with Stripe to get paid",
      description: "Connect your account with Stripe to receive payments.",
      completed: currentUser.stripeAccountId,
    },
    {
      id: 3,
      title: "Verify your ID with Stripe Identity",
      description:
        "Verify your identity to ensure trust and safety in the community.",
      completed: idVerified,
    },
  ];
  const handleStripeOnboarding = async () => {
    if (!currentUser) return;
    const body = JSON.stringify({
      uid: currentUser.uid,
      accountType: "express",
      email: currentUser.email,
    });
    try {
      setIsLoading(true);
      const response = await fetch(
        "https://createstripeaccountandlink-createstripeaccountand-iz3msmwhcq-nw.a.run.app",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body,
        }
      );
      const data = await response.json();
      //window.location.href = data.actionURL;

      window.open(data.actionURL, "_blank");
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      setIsLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={null}
      contentLabel="Complete Verification"
      style={customStyles}
      ariaHideApp={false}
    >
      <div className="p-2">
        {/* Modal Header */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-semibold text-gray-800">
            Complete Your Verification
          </h2>
        </div>

        {/* Progress Bar */}
        <div className="relative pt-1 mb-6">
          <div className="overflow-hidden h-2 text-xs flex rounded bg-gray-200">
            <div
              style={{
                width: `${
                  (steps.filter((s) => s.completed).length / steps.length) * 100
                }%`,
              }}
              className="flex flex-col text-center whitespace-nowrap text-white justify-center bg-emerald-500"
            ></div>
          </div>
        </div>

        {/* Steps List */}
        <ol className="space-y-4 mb-6">
          {steps.map((step) => (
            <li key={step.id} className="flex items-start">
              <span className="flex items-center h-6">
                {step.completed ? (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-emerald-500 mr-2"
                    size="lg"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    className="text-gray-400 mr-2"
                    size="lg"
                  />
                )}
              </span>
              <div>
                <p
                  className={`text-lg font-semibold ${
                    step.completed ? "text-gray-700" : "text-gray-900"
                  }`}
                >
                  {step.title}
                </p>
                {!step.completed && (
                  <p className="text-sm text-gray-600">{step.description}</p>
                )}
              </div>
            </li>
          ))}
        </ol>

        {/* Action Buttons */}
        <div className="flex justify-center gap-3">
          <button onClick={() => navigate("/")} className="btn-secondary px-10">
            Go Back
          </button>
          {!stripeAccountId && (
            <button
              onClick={handleStripeOnboarding}
              className="btn-continue px-4"
            >
              {isLoading ? <BeatLoader /> : <div>Sign up with Stripe</div>}
            </button>
          )}
          {stripeAccountId && !idVerified && (
            //   <VeriffButton
            //     currentUser={currentUser}
            //     idVerified={idVerified}
            //     removeTooltip={true}
            //   />
            <StripeVerifyIdentityButton currentUser={currentUser} />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default VerificationModal;
