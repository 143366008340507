import React, { useState, useEffect, useCallback } from "react";
import towns from "../data/towns";
import { debounce } from "../utils/debounce";

const LocationSearch = ({ onLocationSelect, inputValue, setInputValue }) => {
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [locationState, setLocationState] = useState(null);

  // Debounce function setup with proper cleanup
  const debouncedSearch = useCallback(
    debounce((searchQuery) => {
      if (!searchQuery) {
        setFilteredLocations([]);
        return; // Don't search on empty input
      }
      const results = towns.filter(
        (loc) =>
          loc.town.toLowerCase().includes(searchQuery.toLowerCase()) ||
          loc.county.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredLocations(results);
    }, 300),
    []
  );

  useEffect(() => {
    return () => {
      debouncedSearch.cancel(); // Cancel debounce on unmount to prevent memory leaks
    };
  }, [debouncedSearch]);

  const handleInputChange = (e) => {
    const newQuery = e.target.value;
    setInputValue(newQuery);

    // Clear locations when input is cleared
    if (!newQuery) {
      setLocationState(null);
      setFilteredLocations([]); // Clear filtered locations
      onLocationSelect(null); // Notify parent that location is cleared
    } else {
      // Proceed with debouncing and filtering locations
      debouncedSearch(newQuery);
    }
  };

  const handleLocationSelect = (location) => {
    setLocationState(location);
    setInputValue(`${location.town}, ${location.county}`);
    onLocationSelect(location); // Notify parent of the change
    setFilteredLocations([]); // Clear the filtered list once a location is selected
  };

  return (
    <div className="relative">
      <input
        type="text"
        className="border-2 border-gray-300 rounded p-2 w-full"
        placeholder="Search nearest town..."
        value={inputValue}
        onChange={handleInputChange}
        onFocus={() => {
          if (inputValue) {
            debouncedSearch(inputValue); // Show results if there's a query on focus
          }
        }}
      />
      {filteredLocations.length > 0 && (
        <ul className="absolute w-full list-none bg-white shadow-lg mt-1 z-10">
          {filteredLocations.map((location, index) => (
            <li
              key={index}
              className="py-1 px-2 cursor-pointer hover:bg-gray-100"
              onClick={() => handleLocationSelect(location)}
            >
              {location.town}, {location.county}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LocationSearch;
