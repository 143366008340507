import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const FAQ = () => {
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const navigate = useNavigate();

  const faqs = [
    {
      question: "How much does it cost to list an item for rent?",
      answer: (
        <>
          Listing an item for rent is free. KitShare charges a percentage fee
          based upon the insurance packaged selected by the lender before each
          rental. We only charge this small fee when you get paid.
          <span
            onClick={() => navigate("/insurance")}
            className="text-blue-500 underline hover:font-semibold"
          >
            KitShare Insurance
          </span>{" "}
          page.
        </>
      ),
    },
    {
      question: "How much does KitShare charge renters per rental?",
      answer: (
        <>
          We charge renters a fixed service fee of 10% per rental. This fee
          helps us maintain the platform and provide customer support.
        </>
      ),
    },
    {
      question: "How do I get paid?",
      answer: (
        <>
          We use industry-leading Stripe and Stripe Connect to process payments.
          Once onboarded with Stripe Connect, you'll have access to a
          comprehensive dashboard of transactions and payment history. When a
          rental is confirmed, the money is taken from the renter's account and
          transferred to your Stripe balance on the start date of the rental.
          Payouts to your linked bank account are then initiated on a weekly
          basis.
        </>
      ),
    },
    {
      question: "Does KitShare provide insurance on rentals?",
      answer: (
        <>
          Yes, insurance is available as an option for item owners. With each
          rental request, item owners have the opportunity to insure their item
          by selecting from our tiered insurance packages. For more information,
          please visit our dedicated{" "}
          <span
            onClick={() => navigate("/insurance")}
            className="text-blue-500 underline hover:font-semibold"
          >
            KitShare Insurance
          </span>{" "}
          page.
        </>
      ),
    },
    {
      question: "When do I get paid from KitShare?",
      answer: (
        <>
          Funds are transferred to the item owner's Stripe balance on the start
          date of each rental. KitShare initiates payouts from your Stripe
          balance to your linked bank account on a weekly basis.
        </>
      ),
    },
    {
      question: "Are transactions secure with KitShare?",
      answer: (
        <>
          Yes, absolutely. KitShare uses industry-leading Stripe to process all
          payments and transfers securely. No card information is ever processed
          through our internal systems; all sensitive payment data is handled
          safely by Stripe.
        </>
      ),
    },
  ];

  const toggleFAQ = (index) => {
    setSelectedQuestion(selectedQuestion === index ? null : index);
  };

  return (
    <div className="bg-gray-50 py-10">
      <div className="flex w-full justify-center">
        <h1 className="w-full md:w-2/3 mb-6 text-2xl font-bold text-gray-800 header__center">
          Frequently Asked Questions
        </h1>
      </div>
      <div className="flex justify-center">
        <div className="w-11/12 md:w-8/12 lg:w-6/12">
          <div className="faq">
            {faqs.map((faq, i) => (
              <div
                key={i}
                onClick={() => toggleFAQ(i)}
                className="faq-item mb-4 p-4 rounded-lg bg-white shadow-md hover:shadow-lg transition-shadow duration-200 cursor-pointer"
              >
                <div className="faq-question flex justify-between items-center text-lg font-semibold text-gray-700">
                  {faq.question}
                  <FontAwesomeIcon
                    icon={selectedQuestion === i ? faChevronUp : faChevronDown}
                    className="text-gray-500"
                  />
                </div>
                <div
                  className={`faq-answer overflow-hidden transition-all duration-300`}
                  style={{
                    maxHeight: selectedQuestion === i ? "1000px" : "0",
                  }}
                >
                  <p className="mt-2 text-gray-600 text-base leading-relaxed">
                    {faq.answer}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
