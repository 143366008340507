import React, { useState, useEffect } from "react";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { db } from "../firebase.config";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addDoc,
  collection,
  serverTimestamp,
  getDoc,
  doc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExternalLinkAlt,
  faInfoCircle,
  faPlusCircle,
  faShieldAlt,
  faUpload,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import Footer from "../components/Footer";
import { SyncLoader } from "react-spinners";
import CategorySelector from "../components/CategorySelector";
import { useAuth } from "../hooks/useAuth";
import VerificationModal from "../components/VerificationModal";

const useSafeDispatch = (dispatch) => {
  const mounted = React.useRef(true);

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  return (action) => {
    if (mounted.current) {
      dispatch(action);
    }
  };
};

const NewListing = () => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    dailyRate: "",
    threePlusDayRate: "",
    sevenPlusDayRate: "",
    location: "",
    deposit: "",
  });
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [userUidState, setUserUidState] = useState("");
  const [userExistingLocation, setUserExistingLocation] = useState({});
  const [userExistingGeoLocation, setUserExistingGeoLocation] = useState({});
  const [
    showStripeConnectOnboardingPopup,
    setShowStripeConnectOnboardingPopup,
  ] = useState(false);
  const [imageURLs, setImageURLs] = useState([]); // URLs for displaying thumbnails
  const [imageFiles, setImageFiles] = useState([]); // Files for later upload
  const [isLoading, setIsLoading] = useState(false);
  const [openingCloudinaryWidget, setOpeningCloudinaryWidget] = useState(false);

  const safeSetShowSpinner = useSafeDispatch(setShowSpinner);

  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const {
    title,
    description,
    dailyRate,
    threePlusDayRate,
    sevenPlusDayRate,
    deposit,
  } = formData;

  useEffect(() => {
    loadCloudinaryScript(() => {});
  }, []);

  const loadCloudinaryScript = (callback) => {
    const existingScript = document.getElementById("cloudinaryWidgetScript");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = "https://widget.cloudinary.com/v2.0/global/all.js";
      script.id = "cloudinaryWidgetScript";
      document.body.appendChild(script);
      script.onload = () => {
        if (callback) callback();
      };
    } else if (callback) {
      callback();
    }
  };

  const getUserExistingLocation = async (userUid) => {
    try {
      const userRef = doc(db, "users", userUid);
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        setUserExistingGeoLocation(userSnap.data()._geoloc);
        setUserExistingLocation(userSnap.data().location);
      }
    } catch (error) {
      console.error("Failed to get user location:", error);
      toast.error("Failed to retrieve user location. Please try again.");
    }
  };

  const checkStripeConnectOnboardStatus = async () => {
    try {
      const userRef = doc(db, "users", currentUser.uid);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists && !userDoc.data().stripeAccountId) {
        setShowStripeConnectOnboardingPopup(true);
      }
    } catch (error) {
      console.error("Failed to check Stripe onboarding status:", error);
      toast.error("Failed to verify Stripe onboarding. Please try again.");
    }
  };

  const handleStripeOnboarding = async () => {
    if (!currentUser) return;
    const body = JSON.stringify({
      uid: currentUser.uid,
      accountType: "express",
      email: currentUser.email,
    });
    try {
      setIsLoading(true);
      const response = await fetch(
        "https://createstripeaccountandlink-createstripeaccountand-iz3msmwhcq-nw.a.run.app",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body,
        }
      );
      const data = await response.json();
      //window.location.href = data.actionURL;

      window.open(data.actionURL, "_blank");
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        if (!user.emailVerified) {
          toast.error(
            "You need to verify your email before creating a listing. Please check your emails for a verification link.",
            {
              autoClose: 5000,
            }
          );
          navigate("/");
        } else {
          getUserExistingLocation(user.uid);
          setUserUidState(user.uid);
          checkStripeConnectOnboardStatus();
        }
      } else {
        toast.error("You need to be signed in to create a listing.", {
          autoClose: 3000,
        });
        navigate("/sign-in");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleCategorySelected = (category) => {
    setSelectedCategory(category);
    setSelectedSubcategory(null);
  };

  const handleSubcategorySelected = (subcategory) => {
    setSelectedSubcategory(subcategory);
  };

  const addListingToUserOwnedListings = async (listingId) => {
    try {
      const userRef = doc(db, "users", currentUser.uid);
      await updateDoc(userRef, {
        ownedListings: arrayUnion(listingId),
      });
    } catch (error) {
      console.error("Failed to add listing to user-owned listings:", error);
      toast.error("Failed to update user-owned listings. Please try again.");
    }
  };

  const ratesAreCorrect = () => {
    return dailyRate > threePlusDayRate && threePlusDayRate > sevenPlusDayRate;
  };

  const createNewListing = async (e) => {
    e.preventDefault();

    if (!ratesAreCorrect()) {
      toast.error(
        "Something looks wrong with your rates. Daily rate should be greater than 3+ day rate which should be greater than 7+ day rate.",
        { autoClose: 5000 }
      );
      return;
    }

    if (
      !title ||
      !description ||
      !dailyRate ||
      imageURLs.length === 0 ||
      !selectedCategory
    ) {
      toast.error(
        "Missing form information. Please ensure all required fields are filled and images are uploaded.",
        {
          autoClose: 5000,
        }
      );
      return;
    }

    const auth = getAuth();
    const user = auth.currentUser;

    const geoloc = {
      lat: userExistingGeoLocation.lat,
      lng: userExistingGeoLocation.lng,
    };

    const loc = {
      town: userExistingLocation.town,
      county: userExistingLocation.county,
    };

    try {
      safeSetShowSpinner(true);

      console.log("Form data is valid, creating new listing...");

      const listingRef = await addDoc(collection(db, "listings"), {
        title,

        category: selectedCategory.value,
        subcategory: selectedSubcategory ? selectedSubcategory.value : "",
        description,
        ownerUid: user?.uid,
        dailyRate,
        threePlusDayRate,
        sevenPlusDayRate,
        deposit: deposit > 0 ? deposit : "",
        imageURLs, // Array of image URLs to be saved to Firestore
        location: loc,
        _geoloc: geoloc,
        status: "active",
        created: serverTimestamp(),
      });

      await addListingToUserOwnedListings(listingRef.id);

      safeSetShowSpinner(false);
      toast.success("Your listing has been successfully created!", {
        autoClose: 2000,
      });
      navigate(`/my-listings`);
    } catch (error) {
      safeSetShowSpinner(false);
      console.error("Error creating new listing:", error);
      toast.error("Failed to create listing. Please try again.");
    }
  };

  // Function to handle changes in input fields
  const onChange = (e) => {
    let value = e.target.value;
    if (e.target.type === "number" && value !== "") {
      value = parseFloat(value);
    }
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: value,
    }));
  };

  const cloudName = "prodcloudinary";
  const uploadPreset = "kitShareUploadPreset";

  // Open Cloudinary Upload Widget
  const openUploadWidget = () => {
    window.cloudinary
      .createUploadWidget(
        {
          cloudName: cloudName,
          uploadPreset: uploadPreset,
          multiple: true,
          maxFiles: 5 - imageFiles.length, // Limit number of uploads to remaining slots
          sources: ["local", "camera"],
          folder: "listingImages",
          context: {
            alt: "user_uploaded_image",
            caption: "Uploaded on KitShare",
          },
          resourceType: "image",
        },
        (error, result) => {
          if (!error && result && result.event === "success") {
            setImageFiles((prevFiles) => [...prevFiles, result.info]); // Save the file info for later upload
            setImageURLs((prevUrls) => [...prevUrls, result.info.secure_url]); // Display the uploaded image as thumbnail
            console.log("Image uploaded successfully:", result.info.secure_url);
          } else if (error) {
            console.error("Cloudinary upload error:", error);
            toast.error("Failed to upload image. Please try again.");
          }
        }
      )
      .open();
  };

  // Function to remove an uploaded image
  const removeImage = (index) => {
    setImageFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setImageURLs((prevUrls) => prevUrls.filter((_, i) => i !== index));
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "rgba(255,255,255,0.8)",
    },
  };

  return (
    <div className="force-footer">
      <div className="main-content">
        <div className="flex w-full justify-center">
          <h1 className="w-2/3 text-center my-5 text-xl font-bold header__center">
            Create New Listing
          </h1>
        </div>

        {currentUser ? (
          <VerificationModal
            isOpen={!currentUser.idVerified || !currentUser.stripeAccountId}
            idVerified={currentUser.idVerified}
            stripeAccountId={currentUser.stripeAccountId}
            currentUser={currentUser}
          />
        ) : null}

        <div className="flex justify-center bg-gray-50">
          <form
            className="w-11/12 md:w-2/3 xl:w-4/12"
            onSubmit={createNewListing}
          >
            <div className="flex flex-col items-center py-4">
              {/* Display image thumbnails */}
              {imageURLs.length > 0 && (
                <div className="flex flex-wrap gap-2 mb-4">
                  {imageURLs.map((url, index) => (
                    <div key={index} className="relative">
                      <img
                        src={url}
                        alt="Uploaded"
                        className="w-20 h-20 object-cover rounded-md shadow-md"
                      />
                      <button
                        type="button"
                        className="absolute top-0 right-0 bg-red-500 text-white rounded-full px-2 hover:bg-red-700"
                        onClick={() => removeImage(index)}
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                </div>
              )}

              {imageFiles.length < 5 && (
                <button
                  className="btn-secondary px-4"
                  onClick={openUploadWidget}
                  type="button"
                  aria-label="Upload Images"
                >
                  <FontAwesomeIcon icon={faUpload} /> Upload Images (max 5)
                </button>
              )}
            </div>

            <div className="flex flex-col py-2 w-full mb-2">
              <label className="label">Listing Title</label>
              <input
                className="shadow-md"
                type="text"
                maxLength={35}
                id="title"
                value={title}
                onChange={onChange}
                placeholder="Enter the title of your listing"
                required
              />
            </div>

            <CategorySelector
              onCategorySelected={handleCategorySelected}
              onSubcategorySelected={handleSubcategorySelected}
            />

            <div className="flex flex-col w-full py-2 mb-2 mt-3">
              <label className="label">Listing Description</label>
              <textarea
                placeholder="Enter listing details"
                value={description}
                id="description"
                onChange={onChange}
                className="border-2 p-2 shadow-md"
                required
                rows={10}
              />
            </div>

            <div className="flex flex-col py-2 w-full mb-2">
              <label className="label">Rental price (€):</label>
              <div className="flex flex-col sm:flex-row items-center sm:justify-between gap-2">
                <div className="w-full sm:w-1/3">
                  <label className="label">Daily Rate</label>
                  <input
                    className="shadow-md"
                    type="number"
                    min={1}
                    required
                    step="0.01"
                    id="dailyRate"
                    value={dailyRate}
                    onChange={onChange}
                  />
                </div>
                <div className="w-full sm:w-1/3">
                  <label className="label">3+ Day Rate</label>
                  <input
                    className="shadow-md"
                    type="number"
                    step="0.01"
                    id="threePlusDayRate"
                    placeholder="Optional"
                    value={threePlusDayRate}
                    onChange={onChange}
                  />
                </div>
                <div className="w-full sm:w-1/3">
                  <label className="label">7+ Day Rate</label>
                  <input
                    className="shadow-md"
                    type="number"
                    placeholder="Optional"
                    id="sevenPlusDayRate"
                    step="0.01"
                    value={sevenPlusDayRate}
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>

            <div className="w-full my-4">
              <div className="bg-white rounded-lg shadow-md p-4 mb-1">
                <label className="text-lg font-semibold mb-4 block">
                  Deposit Required?
                </label>
                <div className="flex flex-col gap-4">
                  {/* Deposit Information */}
                  <div className="flex items-start gap-3">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="text-teal-500 mt-1"
                    />
                    <p className="text-sm text-gray-700">
                      You can request a deposit for your item to help ensure its
                      safe return.
                    </p>
                  </div>
                  {/* Insurance Opportunity */}
                  <div className="flex items-start gap-3">
                    <FontAwesomeIcon
                      icon={faShieldAlt}
                      className="text-teal-500 mt-1"
                    />
                    <p className="text-sm text-gray-700">
                      You'll also have the opportunity to{" "}
                      <span
                        className="text-teal-600 font-semibold hover:underline hover:text-teal-800 cursor-pointer"
                        onClick={() => navigate("/insurance")}
                      >
                        insure your item
                      </span>{" "}
                      through KitShare for each rental.
                    </p>
                  </div>
                  {/* Terms & Conditions */}
                  <div className="flex items-start gap-3">
                    <FontAwesomeIcon
                      icon={faExternalLinkAlt}
                      className="text-teal-500 mt-1"
                    />
                    <p className="text-sm text-gray-700">
                      For more details, please review our{" "}
                      <span
                        className="text-teal-600 font-semibold hover:underline hover:text-teal-800 cursor-pointer"
                        onClick={() => navigate("/terms-and-conditions")}
                      >
                        Terms & Conditions
                      </span>
                      .
                    </p>
                  </div>
                </div>
              </div>

              <input
                className="shadow-md"
                type="number"
                placeholder="Optional Deposit"
                min={1}
                step="0.01"
                id="deposit"
                value={deposit}
                onChange={onChange}
              />
            </div>

            <div className="flex justify-center">
              <button
                type="submit"
                className="btn-continue px-7"
                aria-label="Create Listing"
                disabled={showSpinner}
              >
                {showSpinner ? (
                  <SyncLoader size={10} margin={2} color="white" />
                ) : (
                  <span className="text-base">
                    <FontAwesomeIcon icon={faPlusCircle} color="white" /> Create
                    Listing
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default NewListing;
