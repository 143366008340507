import React, { useState, useEffect } from "react";
import ListingCard from "./ListingCard";
import { db } from "../firebase.config"; // Adjust the path as needed
import {
  collection,
  query,
  where,
  doc,
  orderBy,
  getDoc,
  limit,
  getDocs,
} from "firebase/firestore";
import { useAuth } from "../hooks/useAuth"; // Assuming there's a useAuth hook

const LatestListings = () => {
  const [listings, setListings] = useState([]);
  const [title, setTitle] = useState("Latest Listings Added to the Site");
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchListings = async () => {
      try {
        let listingsQuery;

        if (currentUser) {
          // User is logged in, fetch their location and find listings in their county
          const userRef = doc(db, "users", currentUser.uid);
          const userSnap = await getDoc(userRef);
          let userCounty;

          if (userSnap.exists()) {
            userCounty = userSnap.data().location.county;
            setTitle(`Latest Listings in ${userCounty}`);
          } else {
            console.log("No such document!");
          }

          listingsQuery = query(
            collection(db, "listings"),
            where("location.county", "==", userCounty),
            orderBy("created", "desc"),
            limit(8)
          );
        } else {
          // User is not logged in, fetch the latest 8 listings
          listingsQuery = query(
            collection(db, "listings"),
            orderBy("created", "desc"),
            limit(8)
          );
        }

        const listingDocs = await getDocs(listingsQuery);
        const fetchedListings = listingDocs.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setListings(fetchedListings);
      } catch (error) {
        console.error("Error fetching listings:", error);
      }
    };

    fetchListings();
  }, [currentUser]);

  return (
    <div className="flex flex-col justify-center items-center align-center">
      <h1 className="text-2xl font-semibold mb-5 md:mb-8">{title}</h1>
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-10 mb-5">
        {listings.length > 0 ? (
          listings.map((listingDetails) => (
            <ListingCard
              key={listingDetails.id}
              listingDetails={listingDetails}
            />
          ))
        ) : (
          <p>No listings found.</p>
        )}
      </div>
    </div>
  );
};

export default LatestListings;
