import React, { useState } from "react";
import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { db } from "../../firebase.config";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import { convertToTimeZone } from "date-fns-timezone";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faX } from "@fortawesome/free-solid-svg-icons";
import firestoreTimestampToDate from "../../utils/firestoreTimestampToDate";
import OwnerInsuranceModal from "./OwnerInsuranceModal";

const AvailabilityRequestOwner = ({
  message,
  onOpenModal,
  currentUser,
  recipientDetails,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isChecklistModalOpen, setIsChecklistModalOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null); // For storing selected insurance package
  const rentalRequestId = message.rentalRequestId;

  // Destructure rental request message details
  const {
    listingTitle,
    startDate,
    content,
    endDate,
    totalCost,
    rate,
    numberOfDays,
    listingImage,
    serviceFee,
    rentalCost,
  } = message;

  // Handle deposit (if any)
  let deposit = message.deposit || 0;

  // Get email and username of requestor
  const { email, username } = recipientDetails;

  // Convert Firestore Timestamps to JavaScript Dates
  const startDateDate = startDate.toDate();
  const endDateDate = endDate.toDate();

  // Date formatting function
  const formatDate = (date) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const suffix = ["th", "st", "nd", "rd"][((day % 100) - 20) % 10] || "th";

    return `${day}${suffix} ${month}`;
  };

  // Readable start and end dates
  const readableStartDate = formatDate(startDateDate);
  const readableEndDate = formatDate(endDateDate);

  // Send confirmation email after availability confirmation
  const sendAvailabilityConfirmedEmail = async () => {
    try {
      const body = JSON.stringify({
        renterUsername: username,
        renterEmail: email,
        ownerUsername: currentUser.username,
        listingTitle,
        numberOfDays,
        startDate: firestoreTimestampToDate(startDate).toLocaleDateString(),
        endDate: firestoreTimestampToDate(endDate).toLocaleDateString(),
        rate,
        serviceFee,
        totalCost,
      });

      setIsLoading(true);
      const response = await fetch(
        `https://availabilityconfirmedemail-availabilityconfirmede-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      const data = await response.json();
      console.log(data);
      setIsLoading(false);
      console.log("Email sent successfully");
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Failed to send confirmation email.");
    }
  };

  // Open the checklist modal
  const showChecklistModal = () => {
    setIsChecklistModalOpen(true);
  };

  // Handle availability confirmation and insurance package selection
  const handleConfirmAvailability = async () => {
    if (!selectedPackage) {
      toast.error("Please select an insurance package.");
      return;
    }

    try {
      setIsLoading(true);
      const messageRef = doc(
        db,
        "rental-requests",
        rentalRequestId,
        "messages",
        message.id
      );

      // Update the message in Firestore to reflect the confirmation and insurance package
      await updateDoc(messageRef, {
        type: "availability-confirmed",
        insurancePackage: selectedPackage,
        createdAt: serverTimestamp(),
      });

      // Update the rental request document with the selected insurance package from the owner
      // Also change the status of the rental-request to availability-confirmed
      const rentalRequestRef = doc(db, "rental-requests", rentalRequestId);
      await updateDoc(rentalRequestRef, {
        insurancePackage: selectedPackage,
        status: "availability-confirmed",
      });

      // Send the email to the requestor
      await sendAvailabilityConfirmedEmail();

      setIsLoading(false);
      toast.success("Requestor has been notified of availability.", {
        autoClose: 3000,
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Failed to confirm availability.");
    }
  };

  // Format Firebase timestamp to Dublin timezone
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "";

    const date = timestamp.toDate();
    const dublinDate = convertToTimeZone(date, { timeZone: "Europe/Dublin" });
    return format(dublinDate, "MMM dd, yyyy HH:mm");
  };

  return (
    <div>
      {/* Insurance Modal */}
      <OwnerInsuranceModal
        isOpen={isChecklistModalOpen}
        onClose={() => setIsChecklistModalOpen(false)}
        handleConfirm={handleConfirmAvailability}
        rentalRequest={message}
        setSelectedPackage={setSelectedPackage} // Pass setter function for package selection
      />

      <h2 className="text-center font-semibold">Availability Request</h2>
      <p className="text-center my-5 new-line">{content}</p>

      <div className="flex flex-col items-center my-3">
        <img src={listingImage} alt="listing" className="w-36 rounded-md"></img>
        <p className="font-semibold mt-2">{listingTitle}</p>
      </div>

      <div className="flex flex-col items-center gap-2">
        <p>
          <span className="font-bold">Your earnings:</span> €
          {rentalCost.toFixed(2)}
        </p>
        <p>
          <span className="font-bold">Duration:</span> {numberOfDays} days
        </p>
        <p>{`${readableStartDate} - ${readableEndDate}`}</p>
        {deposit && (
          <p>
            <span className="font-bold">Required deposit:</span> €
            {deposit.toFixed(2)}
          </p>
        )}
      </div>

      <div className="flex flex-col items-center align-center justify-center mt-3 mb-2 gap-3">
        <button
          className="p-2 btn-confirm"
          onClick={() => showChecklistModal()}
          disabled={isLoading}
        >
          {isLoading ? (
            <BeatLoader />
          ) : (
            <p>
              <FontAwesomeIcon icon={faCheck} /> Yes, it's available
            </p>
          )}
        </button>
        <button className="py-2 px-5 btn-delete" onClick={onOpenModal}>
          <FontAwesomeIcon icon={faX} /> Reject Request
        </button>
      </div>

      <div>
        <p className="text-xs text-gray-100 text-right">
          {formatTimestamp(message.createdAt)}
        </p>
      </div>
    </div>
  );
};

export default AvailabilityRequestOwner;
