import React, { useEffect, useState } from "react";
import { db } from "../firebase.config";
import { Elements } from "@stripe/react-stripe-js";
import { doc, getDoc } from "firebase/firestore";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faMoneyBill,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import CheckoutForm from "../components/CheckoutForm"; // make sure the path is correct
import firestoreTimestampToDate from "../utils/firestoreTimestampToDate";
import { toast } from "react-toastify";
import { useAuth } from "../hooks/useAuth";
import { BeatLoader } from "react-spinners";

// Load Stripe with your public key
const stripePromise = loadStripe(
  "pk_test_51P5Qf6AyrpazurttmWPqdBQGwW7pr7EtqlSNEp63XJwJNgHtyQgWp5U82x3S0Nfpj9GzlwhzFbXh26pcVLTVBoXM00f7ruZuW5"
);

const ConfirmRental = () => {
  const [listingOwnerDetails, setListingOwnerDetails] = useState("");
  const [deposit, setDeposit] = useState(0);
  const [clientSecret, setClientSecret] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const { currentUser } = useAuth();

  const getListingOwnerDetails = async (listingOwnerUid) => {
    const ownerDocRef = doc(db, "users", listingOwnerUid);
    const ownerSnap = await getDoc(ownerDocRef);
    if (ownerSnap.exists()) {
      setListingOwnerDetails(ownerSnap.data());
    } else {
      setListingOwnerDetails({ username: null, location: null });
    }
  };

  useEffect(() => {
    const { ownerUid } = location.state.message;
    getListingOwnerDetails(ownerUid);
    if (location.state.message) {
      setDeposit(location.state.message.deposit);
    }
  }, [location]);

  const { message, rentalRequestChatId } = location.state;
  const {
    listingTitle,
    listingImage,
    numberOfDays,
    rate,
    rentalCost,
    serviceFee,
    startDate,
    endDate,
    totalCost,
    ownerUid,
    listingId,
  } = message;

  const formattedStartDate =
    firestoreTimestampToDate(startDate)?.toDateString() || "Loading...";
  const formattedEndDate =
    firestoreTimestampToDate(endDate)?.toDateString() || "Loading...";

  // Options to pass to create stripe elements ()
  const options = {
    mode: "payment",
    currency: "eur",
    amount: totalCost * 100,
  };

  return (
    <div>
      <div className="flex w-full justify-center">
        <h1 className="w-2/3 text-center my-5 text-2xl font-bold header__center">
          Confirm Your Rental
        </h1>
      </div>

      {/* Main Flex Container */}
      <div className="flex flex-col-reverse sm:flex-row justify-center gap-10">
        {/* Left Hand Side - Rental Details */}

        <div className="w-full sm:w-1/3 p-3 sm:p-0">
          <p className="font-semibold text-xl mb-2">Rental Details</p>
          {/* Listing pricing details */}
          <div className="bg-gray-100 shadow-md flex flex-col gap-3 rounded-md p-2">
            <p>
              <span className="font-semibold">Number of days: </span>{" "}
              {numberOfDays}
            </p>
            <p>
              <span className="font-semibold">Dates:</span> {formattedStartDate}{" "}
              - {formattedEndDate}
            </p>
            {deposit && deposit > 0 ? (
              <p>
                <span className="font-semibold">Required Deposit:</span> €
                {deposit.toFixed(2)}
              </p>
            ) : null}
            <p>
              <span className="font-semibold">Rate:</span> €{rate.toFixed(2)}
            </p>
            {/* <div>
            {threeDayPlusRate ? <p>3+ Day Rate: €{threeDayPlusRate}</p> : null}
          </div>
          <div>
            {sevenDayPlusRate ? <p>7+ Day Rate: €{sevenDayPlusRate}</p> : null}
          </div> */}
            <p className="border-t-2 border-gray-400 pt-4">
              €{rate.toFixed(2)} x {numberOfDays} days = €
              {rentalCost.toFixed(2)}
            </p>
            <p>
              <span className="font-semibold">Service Fee (10%):</span> €
              {serviceFee.toFixed(2)}
            </p>
            <p className="border-t-2 border-gray-400 pt-4">
              <span className="font-semibold">Total:</span> €
              {totalCost.toFixed(2)}
            </p>
          </div>

          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm
              message={message}
              rentalRequestChatId={rentalRequestChatId}
              listingOwnerDetails={listingOwnerDetails}
            />
          </Elements>

          {/* Cancellation policy */}
          <div className="mt-5 bg-gray-100 p-2 rounded-md shadow-md">
            <div className="flex flex-col ">
              <h2 className="font-semibold text-lg">Cancellation Policy</h2>
              <p>
                Our goal is to provide a flexible and fair cancellation policy
                for our customers while ensuring the sustainability of our
                rental services. Please review our cancellation terms below:
              </p>
              <h3 className="font-semibold mt-5">Cancellation Terms:</h3>
              <ul className="flex flex-col gap-1">
                <li>
                  <span className="font-semibold">
                    7 days or more before rental start date:
                  </span>{" "}
                  No cancellation fee.
                </li>
                <li>
                  <span className="font-semibold">
                    Less than 7 days but more than 1 day before rental start
                    date:
                  </span>{" "}
                  10% cancellation fee.
                </li>
                <li>
                  <span className="font-semibold">
                    1 day or less before rental start date:
                  </span>{" "}
                  20% cancellation fee.
                </li>
                <li>
                  <span className="font-semibold">Special Circumstances</span>:
                  Full refunds or waivers of the cancellation fee may be
                  considered for documented emergencies or unforeseen events,
                  evaluated on a case-by-case basis.
                </li>
              </ul>
              <p>
                We believe this policy strikes a balance between flexibility for
                our customers and the necessary safeguards for our operations.
                If you have any questions or require assistance, please contact
                our support team.
              </p>
              <p className="mt-4">
                <strong>Contact Us</strong>: info@kitshare.ie
              </p>
            </div>
          </div>
        </div>

        {/* Right Hand Side - Listing and User Details */}
        <div className="flex flex-col p-2">
          {/* Listing Details */}
          <div className="flex flex-col justify-center align-center items-center">
            <h2 className="font-semibold text-xl mb-4">{listingTitle}</h2>
            <img src={listingImage} className="w-48 mb-5" alt="listingImage" />
          </div>
          {/* User details */}
          <div className="flex justify-between bg-gray-100 rounded-md p-2 shadow-md">
            <p className="text-base mb-2">
              <span className="font-semibold">
                {listingOwnerDetails?.username ?? ""}
              </span>{" "}
              in {listingOwnerDetails?.location?.town ?? ""}
            </p>
            <div className="ml-5 flex items-center mb-2 gap-2">
              <p className="text-sm">
                {listingOwnerDetails.averageReviewRating
                  ? listingOwnerDetails.averageReviewRating
                  : "N/A"}{" "}
                <FontAwesomeIcon icon={faStar} color="gold" />
              </p>
              <p className="text-sm">
                {" "}
                (
                {listingOwnerDetails.reviewCount > 0
                  ? listingOwnerDetails.reviewCount
                  : 0}
                )
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmRental;
