import React, { createContext, useContext, useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { db } from "../firebase.config"; // Adjust the import path as necessary
import { doc, getDoc } from "firebase/firestore";

// Create a context with a default value
export const AuthContext = createContext({
  currentUser: null,
  isLoading: true,
});

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Function to transform Firebase User to UserDetails
  const transformUser = async (user) => {
    // Fetch additional user details from Firestore
    const userRef = doc(db, "users", user.uid);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      // Combine data from Firebase Auth and Firestore
      const userDetails = {
        uid: userSnap.id,
        username: userSnap.data().username, // Adjust according to your Firestore structure
        email: userSnap.data().email,
        phoneNumber: userSnap.data().phoneNumber,
        signUpTimestamp: userSnap.data().signUpTimestamp,
        location: userSnap.data().location,
        idVerified: userSnap.data().idVerified,
        _geoloc: userSnap.data()._geoloc,
        favourites: userSnap.data().favourites,
        profilePictureURL: userSnap.data().profilePictureURL,
        emailVerified: user.emailVerified,
        stripeAccountId: userSnap.data().stripeAccountId,
      };
      return userDetails;
    }
    return null;
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const transformedUser = await transformUser(user);
        setCurrentUser(transformedUser);
      } else {
        setCurrentUser(null);
      }
      setIsLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, isLoading }}>
      {!isLoading && children}
    </AuthContext.Provider>
  );
};
