import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { getAuth } from "firebase/auth";
import { toast } from "react-toastify";

const stripePromise = loadStripe(
  "pk_test_51P5Qf6AyrpazurttmWPqdBQGwW7pr7EtqlSNEp63XJwJNgHtyQgWp5U82x3S0Nfpj9GzlwhzFbXh26pcVLTVBoXM00f7ruZuW5"
);

const StripeVerifyIdentityButton = ({ currentUser }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async (event) => {
    event.preventDefault();

    const stripe = await stripePromise;

    if (!stripe) {
      console.error("Stripe.js has not loaded yet.");
      toast.error("Failed to load payment gateway, please refresh the pgage");
      return;
    }

    if (!currentUser || !currentUser.uid || !currentUser.email) {
      console.error("Invalid user information.");
      toast.error("User information is missing, please log in again");
      return;
    }

    setIsLoading(true);

    const body = JSON.stringify({
      userUid: currentUser.uid,
      email: currentUser.email,
    });

    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const idToken = await user.getIdToken();

      const response = await fetch(
        "https://createverificationsession-createverificationsessi-iz3msmwhcq-nw.a.run.app",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: body,
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`HTTP error ${response.status}: ${errorText}`);
        toast.error("Failed to initiate verification, please try again.");
        return;
      }

      const session = await response.json();

      if (session.error) {
        console.error(session.error);
        toast.error("Failed to start verification session, please try again.");
        return;
      }

      // Show the verification modal
      const { error } = await stripe.verifyIdentity(session.clientSecret);

      if (error) {
        console.error("[Verification error]", error.message);
        toast.error(error.message);
      } else {
        console.log("Verification submitted!");
        toast.success("Verification submitted");
        window.location.reload();
      }
    } catch (err) {
      console.error("Error initiating verification:", err);
      toast.error("An unexpected error occurred, please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <button
        disabled={isLoading}
        onClick={handleClick}
        className="btn-primary px-5"
      >
        {isLoading ? "Processing..." : "Verify Identity"}
      </button>
    </div>
  );
};

export default StripeVerifyIdentityButton;
