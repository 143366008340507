import React, { useState } from "react";
import { convertToTimeZone } from "date-fns-timezone";
import { format } from "date-fns";
import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { db } from "../../firebase.config";
import OwnerInsuranceModal from "./OwnerInsuranceModal"; // Assuming you have this component
import { toast } from "react-toastify";

const AvailabilityConfirmedOwner = ({ message, onOpenModal }) => {
  const [isInsuranceModalOpen, setIsInsuranceModalOpen] = useState(false);
  const [selectedInsurancePackage, setSelectedInsurancePackage] = useState(
    message.insurancePackage || { name: "None" }
  ); // Initialize with current insurance package or 'None'

  const rentalRequestId = message.rentalRequestId;
  const {
    listingTitle,
    startDate,
    endDate,
    content,
    numberOfDays,
    listingImage,
    rentalCost,
  } = message;

  let deposit;
  if (message.deposit) {
    deposit = message.deposit;
  }

  // Convert Firestore Timestamp to JavaScript Date object
  const startDateDate = startDate.toDate();
  const endDateDate = endDate.toDate();

  const formatDate = (date) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const suffix = ["th", "st", "nd", "rd"][((day % 100) - 20) % 10] || "th";

    return `${day}${suffix} ${month}`;
  };

  // Using the converted Date objects from above
  const readableStartDate = formatDate(startDateDate);
  const readableEndDate = formatDate(endDateDate);

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "";
    const date = timestamp.toDate();
    const dublinDate = convertToTimeZone(date, { timeZone: "Europe/Dublin" });
    return format(dublinDate, "MMM dd, yyyy HH:mm");
  };

  // Open/Close insurance modal
  const openInsuranceModal = () => setIsInsuranceModalOpen(true);
  const closeInsuranceModal = () => setIsInsuranceModalOpen(false);

  const updateInsurancePackage = async (pkg) => {
    try {
      const messageRef = doc(
        db,
        "rental-requests",
        rentalRequestId,
        "messages",
        message.id
      );
      await updateDoc(messageRef, {
        insurancePackage: pkg,
      });

      const rentalRequestRef = doc(db, "rental-requests", rentalRequestId);
      await updateDoc(rentalRequestRef, {
        insurancePackage: pkg,
      });
      setSelectedInsurancePackage(pkg);
      closeInsuranceModal();
      toast.success("Updated insurance package choice", { autoClose: 4000 });
    } catch (error) {
      console.error("Failed to update insurance package: ", error);
    }
  };

  const handleConfirmInsurancePackage = (pkg) => {
    updateInsurancePackage(pkg);
  };

  return (
    <div className="">
      <p className="text-center font-bold my-3 text-emerald-400 text-lg">
        Availability Confirmed
      </p>
      <p className="text-center my-5 new-line">{content}</p>

      <div className="flex flex-col items-center my-3">
        <img src={listingImage} alt="listing" className="w-36 rounded-md" />
        <p className="font-bold mt-2">{listingTitle}</p>
      </div>

      <div className="flex flex-col items-center gap-2">
        <p>
          <span className="font-bold">Your earnings:</span> €
          {(rentalCost - selectedInsurancePackage.fee).toFixed(2)}
        </p>
        <p>
          <span className="font-bold">Duration:</span> {numberOfDays} days
        </p>
        <p>{`${readableStartDate} - ${readableEndDate}`}</p>
        {deposit > 0 && (
          <p>
            <span className="font-bold">Required deposit:</span> €
            {deposit.toFixed(2)}
          </p>
        )}
      </div>

      {/* Display Current Insurance Package */}
      <div className="flex flex-col items-center mt-3 mb-5 border-2 pt-3 pb-4 bg-slate-600 rounded-lg">
        <p className="font-semibold">Current Insurance Package</p>

        {/* No insurance package */}
        {selectedInsurancePackage.name === "None" ? (
          <p>None</p>
        ) : (
          <div className="flex flex-col text-center text-sm">
            <div className="my-1">
              <span className="font-semibold">
                {selectedInsurancePackage.name} Package
              </span>{" "}
              - €{selectedInsurancePackage.fee.toFixed(2)} fee
            </div>
            <div>
              <span className="font-semibold">Coverage </span>- up to €
              {selectedInsurancePackage.maxCoverage}
            </div>
          </div>
        )}
        <button
          className="mt-2 btn-edit text-black py-1 px-4"
          onClick={openInsuranceModal}
        >
          Change Package
        </button>
      </div>

      <p className="text-center text-sm text-orange-300 pb-2">
        Do not hand over the item until the rental is confirmed. The borrower
        first needs to pay and verify.
      </p>

      <div className="flex flex-col items-center align-center justify-center mt-3 mb-2 gap-3">
        <button className="p-2 btn-delete" onClick={() => onOpenModal()}>
          Retract Confirmation
        </button>
      </div>

      <div>
        <p className="text-xs text-gray-100 text-right">
          {formatTimestamp(message.createdAt)}
        </p>
      </div>

      {/* Insurance Modal */}
      <OwnerInsuranceModal
        isOpen={isInsuranceModalOpen}
        onClose={closeInsuranceModal}
        handleConfirm={handleConfirmInsurancePackage}
        rentalRequest={message}
        setSelectedPackage={setSelectedInsurancePackage}
        defaultSelectedPackage={selectedInsurancePackage}
      />
    </div>
  );
};

export default AvailabilityConfirmedOwner;
