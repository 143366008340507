import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import stripeConnect from "../assets/stripe-connect.svg";
import { toast } from "react-toastify";
import { db } from "../firebase.config"; // Adjust the import path as necessary
import {
  doc,
  getDoc,
  collection,
  where,
  query,
  getDocs,
} from "firebase/firestore";
import { useAuth } from "../hooks/useAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faBookmark,
  faEuro,
  faList,
  faStar,
  faTimesCircle,
  faUser,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import defaultProfilePicture from "../assets/profilePicture.png";
import { BeatLoader } from "react-spinners";

const OwnersHub = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [stripeStatus, setStripeStatus] = useState("");
  const [stripeAccountId, setStripeAccountId] = useState("");
  const [userData, setUserData] = useState({});
  const [userListings, setUserListings] = useState([]);
  const [profilePictureURL, setProfilePictureURL] = useState("");
  const [hasStripeConnectedAccount, setHasStripeConnectedAccount] =
    useState(false);
  const { currentUser } = useAuth();

  const navigate = useNavigate();

  // Status codes used for Stripe
  const STATUS_CODES = {
    TRANSFERS_ENABLED: 1,
    REQUIREMENTS_DUE: 2,
    ONBOARDING_NOT_COMPLETE: 3,
    TRANSFERS_DISABLED: 4,
    NO_STRIPE_ACCOUNT: 5,
  };

  useEffect(() => {
    if (currentUser) {
      fetchUserData();
      fetchUserListings();
    }
  }, [currentUser]);

  useEffect(() => {
    const fetchStripeStatus = async () => {
      if (!currentUser) return;
      setIsLoading(true);
      try {
        const result = await checkStripeAccountStatus(currentUser);
        console.log(result);
        setStripeStatus(result);
      } catch (error) {
        toast.error("Failed to fetch Stripe account status.");
      } finally {
        setIsLoading(false);
      }
    };
    fetchStripeStatus();
  }, [currentUser]);

  const fetchUserData = async () => {
    const userRef = doc(db, "users", currentUser.uid);
    const docSnap = await getDoc(userRef);
    if (docSnap.exists()) {
      setUserData(docSnap.data());
      if (docSnap.data().profilePictureURL) {
        setProfilePictureURL(docSnap.data().profilePictureURL);
      }
      if (docSnap.data().stripeAccountId) {
        setHasStripeConnectedAccount(true);
        setStripeAccountId(docSnap.data().stripeAccountId);
      }
    }
  };

  const fetchUserListings = async () => {
    try {
      const listingsRef = collection(db, "listings");
      const q = query(listingsRef, where("ownerUid", "==", currentUser.uid));
      const querySnapshot = await getDocs(q);

      const listings = [];
      querySnapshot.forEach((doc) => {
        listings.push({ id: doc.id, ...doc.data() });
      });

      setUserListings(listings); // Set the fetched listings in the state
    } catch (error) {
      console.error("Error fetching user listings:", error);
      toast.error("Failed to fetch user listings.");
    }
  };

  const checkStripeAccountStatus = async (currentUser) => {
    const body = JSON.stringify({ uid: currentUser.uid });
    try {
      const response = await fetch(
        "https://checkstripeaccountstatus-checkstripeaccountstatus-iz3msmwhcq-nw.a.run.app",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body,
        }
      );
      if (!response.ok)
        throw new Error("Failed to fetch Stripe account status");
      return await response.json();
    } catch (error) {
      console.error("Error fetching Stripe account status:", error);
      throw error;
    }
  };

  const handleStripeOnboarding = async () => {
    if (!currentUser) return;
    const body = JSON.stringify({
      uid: currentUser.uid,
      accountType: "express",
      email: currentUser.email,
    });
    try {
      setIsLoading(true);
      const response = await fetch(
        "https://createstripeaccountandlink-createstripeaccountand-iz3msmwhcq-nw.a.run.app",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body,
        }
      );
      const data = await response.json();
      //window.location.href = data.actionURL;

      window.open(data.actionURL, "_blank");
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      setIsLoading(false);
    }
  };

  const generateLoginLink = async (linkType) => {
    if (!currentUser) return;
    const body = JSON.stringify({
      stripeAccountId,
      linkType,
    });
    try {
      setIsLoading(true);
      const response = await fetch(
        "https://createstripeloginlink-createstripeloginlink-iz3msmwhcq-nw.a.run.app",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body,
        }
      );
      const data = await response.json();
      //window.location.href = data.actionURL;
      console(data.actionURL);
      //window.open(data.actionURL, "_blank");
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      setIsLoading(false);
    }
  };

  const launchStripeExpressConnectURL = () => {
    setIsLoading(true);
    window.location.href = stripeStatus.actionURL;
  };

  const renderStripeStatus = () => {
    switch (stripeStatus?.status) {
      case STATUS_CODES.TRANSFERS_ENABLED:
        return "Transfers Enabled";
      case STATUS_CODES.REQUIREMENTS_DUE:
        return "Complete Stripe Requirements";
      case STATUS_CODES.ONBOARDING_NOT_COMPLETE:
        return "Complete Stripe Onboarding";
      case STATUS_CODES.TRANSFERS_DISABLED:
        return "Transfers Disabled - Resolve Issues";
      case STATUS_CODES.NO_STRIPE_ACCOUNT:
        return "No Stripe Account";
      default:
        return "Unknown Stripe Status - Please Contact Support";
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="text-center mb-6">
        <h1 className="text-2xl font-bold header__center">Owners Hub</h1>
      </div>

      {/* Stripe Connect Section */}
      <div className="flex justify-center">
        <div
          className={`bg-white shadow-md rounded-lg p-6 mb-6 border-2 ${
            stripeStatus &&
            stripeStatus.status !== STATUS_CODES.TRANSFERS_ENABLED
              ? "border-red-600"
              : "border-green-500"
          }`}
        >
          <div className="flex items-center justify-center mb-4">
            <img
              src={stripeConnect}
              alt="Stripe Connect"
              className="w-10 mr-2"
            />
            <h2 className="text-xl font-bold">Stripe Connect</h2>
          </div>
          <p className="mb-2 text-gray-700">
            <strong>Status:</strong>{" "}
            <span className="font-semibold">{renderStripeStatus()}</span>
          </p>
          <p className="mb-4 text-gray-700">
            <strong>Message:</strong>{" "}
            <span className="font-semibold">{stripeStatus.message}</span>
          </p>

          {stripeStatus &&
          stripeStatus.status === STATUS_CODES.TRANSFERS_ENABLED ? (
            <button
              className="w-full btn-continue my-2"
              disabled={isLoading}
              onClick={launchStripeExpressConnectURL}
            >
              {isLoading ? (
                <BeatLoader color="white" />
              ) : (
                <div>
                  {" "}
                  <FontAwesomeIcon icon={faArrowUp} className="mr-2" />
                  Open Stripe Dashboard
                </div>
              )}
            </button>
          ) : stripeStatus &&
            stripeStatus.status === STATUS_CODES.NO_STRIPE_ACCOUNT ? (
            <button
              className="w-full btn-stripe stripe-logo-colour my-2 px-3"
              disabled={isLoading}
              onClick={handleStripeOnboarding}
            >
              {isLoading ? (
                <BeatLoader color="white" />
              ) : (
                <div>
                  {" "}
                  <FontAwesomeIcon icon={faEuro} className="mr-2" />
                  Get Paid with Stripe
                </div>
              )}
            </button>
          ) : (
            <button
              className="w-full btn-stripe stripe-logo-colour my-2 px-3"
              disabled={isLoading}
              onClick={() => window.open(stripeStatus.actionURL)}
            >
              {isLoading ? (
                <BeatLoader color="white" />
              ) : (
                <div>
                  {" "}
                  <FontAwesomeIcon icon={faWarning} className="mr-2" />
                  Complete Requirements
                </div>
              )}
            </button>
          )}

          {/* {stripeStatus &&
            stripeStatus.status !== STATUS_CODES.NO_STRIPE_ACCOUNT && (
              <button
                className="w-full btn-stripe stripe-logo-colour my-2 px-3"
                disabled={isLoading}
                onClick={() => generateLoginLink("update")}
              >
                <FontAwesomeIcon icon={faUser} className="mr-2" /> Update
                Account Details
              </button>
            )} */}
        </div>
      </div>

      {/* User Information Section */}
      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <div className="flex items-center gap-4 mb-4">
          {profilePictureURL ? (
            <img
              src={profilePictureURL || defaultProfilePicture}
              alt="Profile"
              className="rounded-full w-12 h-12"
            />
          ) : (
            <div className="rounded-full w-12 h-12 bg-gray-200 flex items-center justify-center">
              <FontAwesomeIcon icon={faUser} className="text-gray-400" />
            </div>
          )}
          <h2 className="text-2xl font-semibold text-gray-800">
            User Information
          </h2>
        </div>
        <div className="flex flex-col gap-2 justify-center align-start items-start">
          <p className="text-gray-700 text-lg ">
            <FontAwesomeIcon icon={faUser} className="text-teal-500" />{" "}
            Username:{" "}
            <span className="font-bold">{userData && userData.username}</span>
          </p>
          <p className="text-gray-700 text-lg ">
            <FontAwesomeIcon icon={faStar} className="text-yellow-500" />{" "}
            Average Review Rating:{" "}
            <span className="font-bold">
              {userData.averageReviewRating
                ? userData.averageReviewRating
                : "N/A"}
            </span>
          </p>
          <p className="text-gray-700 text-lg ">
            <FontAwesomeIcon icon={faList} className="text-green-500" /> Number
            of Listings:{" "}
            <span className="font-bold">
              {userData.ownedListings ? userData.ownedListings.length : 0}
            </span>
          </p>
          <p className="text-gray-700 text-lg ">
            <FontAwesomeIcon icon={faBookmark} className="text-purple-500" />{" "}
            Total Number of Bookings:{" "}
            <span className="font-bold">
              {userData && userData.bookingCount}
            </span>
          </p>
          <p className="text-gray-700 text-lg ">
            <FontAwesomeIcon icon={faTimesCircle} className="text-red-500" />{" "}
            Number of Cancellations:{" "}
            <span className="font-bold">
              {userData.cancellationCount ? userData.cancellationCount : 0}
            </span>
          </p>
        </div>
      </div>

      {/* Listings table with number of bookings */}
      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <h2 className="text-xl font-semibold mb-4">Your Listings</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b">Listing Title</th>
                <th className="py-2 px-4 border-b">Total Bookings</th>
                <th className="py-2 px-4 border-b">Status</th>
                <th className="py-2 px-4 border-b">Actions</th>
              </tr>
            </thead>
            <tbody>
              {userListings.map((listing) => (
                <tr key={listing.id}>
                  <td
                    className="py-2 px-4 border-b hover:font-bold hover:cursor-pointer hover:bg-gray-50"
                    onClick={() => navigate(`/listings/${listing.id}`)}
                  >
                    {listing.title}
                  </td>
                  <td className="py-2 px-4 border-b text-center">
                    {listing.bookings
                      ? listing.bookings.length
                      : "No bookings yet"}
                  </td>
                  <td className="py-2 px-4 border-b text-center font-bold">
                    {listing.status === "active" ? (
                      <span className="text-green-600">Active</span>
                    ) : (
                      <span className="text-red-600">Inactive</span>
                    )}
                  </td>
                  <td className="py-2 px-4 border-b text-center">
                    <button
                      className="btn-continue text-white py-1 px-2 rounded"
                      onClick={() => navigate(`/edit-listing/${listing.id}`)}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Support Information - Placeholder for now */}
      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <h2 className="text-xl font-semibold mb-4">Support Information</h2>
        <p>
          If you have any issues or need assistance, please contact our support
          team at{" "}
          <a href="mailto:support@kitshare.ie" className="text-blue-500">
            support@kitshare.ie
          </a>
          .
        </p>
      </div>

      {/* How to Guides - Placeholder for now */}
      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <h2 className="text-xl font-semibold mb-4">How-to Guides</h2>
        <ul className="list-disc pl-5">
          <li>
            <a href="/guides/listing-your-item" className="text-blue-500">
              How to List Your Item for Rent
            </a>
          </li>
          <li>
            <a href="/guides/managing-rentals" className="text-blue-500">
              Managing Your Rentals
            </a>
          </li>
          <li>
            <a href="/guides/stripe-connect" className="text-blue-500">
              Setting Up and Using Stripe Connect
            </a>
          </li>
        </ul>
      </div>

      {/* Terms and Conditions - Placeholder for now */}
      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <h2 className="text-xl font-semibold mb-4">Terms and Conditions</h2>
        <p>
          Please review our{" "}
          <a href="/terms-and-conditions" className="text-blue-500">
            Terms and Conditions
          </a>{" "}
          to understand your responsibilities and rights when using KitShare.
        </p>
      </div>
    </div>
  );
};

export default OwnersHub;
