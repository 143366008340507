import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { FaShieldAlt, FaCheck, FaTimes, FaArrowLeft } from "react-icons/fa";
import { GiMoneyStack, GiMedal } from "react-icons/gi";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";

const OwnerInsuranceModal = ({
  isOpen,
  onClose,
  handleConfirm,
  rentalRequest,
  setSelectedPackage,
  defaultSelectedPackage,
}) => {
  const [localSelectedPackage, setLocalSelectedPackage] = useState(null);
  const [termsChecked, setTermsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Set the default selected package if it exists
    if (defaultSelectedPackage) {
      setLocalSelectedPackage(defaultSelectedPackage);
      setSelectedPackage(defaultSelectedPackage); // Set the parent state as well
    }
  }, [defaultSelectedPackage, setSelectedPackage]);

  // Updated packages array with new percentages and coverage amounts
  const packages = [
    {
      name: "Bronze",
      costPercentage: 10,
      maxCoverage: 300,
      color: "bg-yellow-800",
      icon: <GiMedal />,
    },
    {
      name: "Silver",
      costPercentage: 15,
      maxCoverage: 600,
      color: "bg-gray-400",
      icon: <GiMedal />,
    },
    {
      name: "Gold",
      costPercentage: 20,
      maxCoverage: 1000,
      color: "bg-yellow-500",
      icon: <GiMoneyStack />,
    },
  ];

  // Adjusted customStyles for responsiveness
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      borderRadius: "2%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "rgba(255,255,255,0.95)",
      width: "90%",
      maxHeight: "90vh",
      overflowY: "auto",
      maxWidth: "500px",
    },
  };

  const handlePackageSelect = (pkg) => {
    const insuranceFee = (rentalRequest.rentalCost * pkg.costPercentage) / 100;
    const selectedPkg = {
      name: pkg.name,
      costPercentage: pkg.costPercentage,
      maxCoverage: pkg.maxCoverage,
      fee: insuranceFee,
    };
    setLocalSelectedPackage(selectedPkg);
    setSelectedPackage(selectedPkg); // Update the parent component with the selected package
  };

  const handleNoInsurance = () => {
    const noInsurancePkg = {
      name: "None",
      costPercentage: 5,
      fee: (rentalRequest.rentalCost * 5) / 100,
      maxCoverage: 0,
    };
    setLocalSelectedPackage(noInsurancePkg);
    setSelectedPackage(noInsurancePkg);
  };

  const handleConfirmClick = () => {
    if (termsChecked && localSelectedPackage) {
      handleConfirm(localSelectedPackage); // Confirm with the selected package
      onClose();
    } else {
      toast.error("Please agree to the terms and conditions.");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Insurance Modal"
      style={customStyles}
      ariaHideApp={false}
    >
      <div className="p-2 sm:p-4 relative">
        {/* Close Button for Mobile */}
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 focus:outline-none"
        >
          <FaTimes size={20} />
        </button>
        <h2 className="text-center font-bold text-xl mb-5">
          Select Your Insurance Package
        </h2>
        <div className="flex flex-col gap-1">
          <p className="text-red-500 font-bold">
            No immediate payment required
          </p>{" "}
          <p className="text-sm text-gray-700 mb-4">
            KitShare takes this fee when you get paid! (First day of rental)
          </p>
        </div>
        {/* Option for no insurance */}
        <div
          className={`p-4 rounded-md border-2 ${
            localSelectedPackage?.name === "None"
              ? "border-red-500 bg-red-50"
              : "border-gray-300"
          } cursor-pointer hover:bg-gray-100 transition-all mb-4`}
          onClick={handleNoInsurance}
        >
          <div className="flex items-center gap-2">
            <div className={`p-2 text-red-600 rounded-full`}>
              <FaTimes />
            </div>
            <p className="font-semibold text-lg text-red-600">No insurance</p>
          </div>
          <p className="mt-2 font-semibold text-sm text-gray-700">
            5% of rental earnings (€
            {((rentalRequest.rentalCost * 5) / 100).toFixed(2)})
          </p>
        </div>
        {/* Insurance Package Selection */}
        <div className="grid grid-cols-1 gap-4 mb-5">
          {packages.map((pkg) => (
            <div
              key={pkg.name}
              className={`p-4 rounded-md border-2 ${
                localSelectedPackage?.name === pkg.name
                  ? "border-green-500 bg-green-50"
                  : "border-gray-300"
              } cursor-pointer hover:bg-gray-100 transition-all`}
              onClick={() => handlePackageSelect(pkg)}
            >
              <div className="flex items-center gap-2">
                <div className={`p-2 text-white ${pkg.color} rounded-full`}>
                  {pkg.icon}
                </div>
                <p className="font-semibold text-lg">{pkg.name} Package</p>
              </div>
              <p className="mt-2 mb-2 font-semibold text-sm text-gray-700">
                {pkg.costPercentage}% of rental earnings (€
                {(
                  (rentalRequest.rentalCost * pkg.costPercentage) /
                  100
                ).toFixed(2)}
                )
              </p>
              <p className="text-sm text-gray-700">
                Insurance Coverage:{" "}
                <span className="font-semibold">Up to €{pkg.maxCoverage}</span>
              </p>
            </div>
          ))}
        </div>
        {/* Terms and Conditions */}
        <div className="mb-4 flex items-center">
          <input
            type="checkbox"
            id="terms"
            checked={termsChecked}
            onChange={(e) => setTermsChecked(e.target.checked)}
            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
          />
          <label htmlFor="terms" className="ml-2 text-sm">
            I have reviewed and agree to the{" "}
            <a href="/terms-and-conditions" className="text-blue-500 underline">
              Terms and Conditions
            </a>{" "}
            for the insurance provided by KitShare.
          </label>
        </div>
        {/* Buttons */}
        <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
          <button
            onClick={onClose}
            className="flex items-center justify-center btn-cancel gap-1 px-4 py-2 w-full sm:w-auto"
          >
            <FaArrowLeft /> Go back
          </button>
          {isLoading ? (
            <BeatLoader />
          ) : (
            <button
              onClick={handleConfirmClick}
              className={`flex items-center justify-center gap-1 px-4 py-2 rounded-md text-white w-full sm:w-auto ${
                termsChecked && localSelectedPackage
                  ? "btn-continue"
                  : "bg-gray-300 cursor-not-allowed"
              }`}
              disabled={!termsChecked || !localSelectedPackage}
            >
              <FaCheck /> Yes, proceed
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default OwnerInsuranceModal;
